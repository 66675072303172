<template>
    <content-details class="place" :title="item.title" :offset-size="4" :type-tags="item.typeTags" :infos="item.infos">
        <template v-if="item">
            <!-- WYSIWYG (Contenu) -->
            <Container
                v-if="item.description && item.description.processed"
                class="place__wysiwyg-wrapper"
                max-width="700px"
                big-padding
                no-margin
            >
                <wysiwyg class="place__wysiwyg" :body="item.description.processed" />
            </Container>

            <!--ADDRESS -->
            <Container v-if="!item.affichage_geo">
                <Container class="place__address-container" max-width="700px" big-padding no-margin>
                    <Row class="place__address" gutter>
                        <el-col :span="24" :md="10" class="place__address-map">
                            <div class="place__address-map-container">
                                <span class="place__address-map-icon">
                                    <img src="~assets/img/map-marker-primary.svg" alt="map-marker" />
                                </span>
                                <a class="place__address-map-link" href="#carte">{{ $t('place.seeOnmap') }}</a>
                            </div>
                        </el-col>
                        <el-col :span="24" :md="14" class="place__address-info">
                            <div class="place__address-info-info-container">
                                <h2 class="place__address-info-title">
                                    {{ $t('place.address') }}
                                </h2>
                                <!--ADDRESS -->
                                <div class="place__address-info-position">
                                    {{ item.adresse.address_line1 }}
                                    <template v-if="item.adresse.address_line2">
                                        , {{ item.adresse.address_line2 }}
                                    </template>

                                    <br />
                                    {{ item.adresse.locality }},
                                    {{ item.adresse.administrative_area }}

                                    <!--Postal code -->
                                    <br />
                                    {{ item.adresse.postal_code }}
                                </div>

                                <!--ADDRESS -->
                                <div class="place__address-info-phones">
                                    <span v-for="phone in item.phone" :key="phone">
                                        {{ phone }}
                                    </span>
                                </div>

                                <a v-if="item.mail" class="place__address-info-talk" :href="`mailto:${item.mail}`">
                                    {{ $t('place.talkWithUs') }}
                                </a>
                            </div>
                        </el-col>
                    </Row>
                </Container>
            </Container>

            <!-- PRECISION -->
            <Container v-if="item.place_details" max-width="700px" big-padding no-margin>
                <p class="place__precision" v-html="item.place_details.processed"></p>
            </Container>

            <!-- OFFICE HOURS -->
            <Container max-width="700px" no-margin big-padding>
                <div v-for="officeHours in item.office_hours" :key="officeHours.internal_id" class="place__hours">
                    <h2 class="place__hours-title">{{ officeHours.time_period }} :</h2>

                    <div v-for="day in officeHours.office_hours" :key="day.day" class="place__hours-period">
                        <span>{{
                            $moment()
                                .day(day.day)
                                .format('dddd')
                                .charAt(0)
                                .toUpperCase() +
                                $moment()
                                    .day(day.day)
                                    .format('dddd')
                                    .slice(1)
                        }}</span>
                        <span v-if="day.starthours"> {{ $moment(day.starthours, 'hmm').format('HH:mm') }}</span>
                        <span v-if="day.endhours">
                            à
                            {{ $moment(day.endhours, 'hmm').format('HH:mm') }}</span
                        >
                        <span v-if="day.comment"> {{ day.comment }}</span>
                    </div>
                </div>
                <div v-if="item.additional_information" class="place__hours">
                    <p class="place__hours-period" v-html="item.additional_information.processed"></p>
                </div>
            </Container>

            <!-- Image -->
            <Container v-if="item.media_image" class="place__image-wrapper" max-width="1073px">
                <media-image :image="item.media_image" size="front_large_21_9" class="place__image"></media-image>
            </Container>

            <!-- Paragraphs -->
            <container class="place__custom_content" max-width="700px" no-margin big-padding>
                <paragraphs :item="item"></paragraphs>
            </container>

            <!-- CARTE -->
            <Container v-if="mapUrl && !item.affichage_geo" class="place__map" max-width="1073px">
                <div class="place__address-info-container map">
                    <h2 class="place__address-info-map-title">
                        {{ item.title }}
                    </h2>

                    <!--ADDRESS -->
                    <div class="place__address-info-position">
                        {{ $t('place.address') }}: <br />
                        {{ item.adresse.address_line1 }}
                        <template v-if="item.adresse.address_line2"> , {{ item.adresse.address_line2 }}</template>

                        <!--Postal code -->
                        <br />
                        {{ item.adresse.postal_code }}

                        <br />
                        {{ item.adresse.locality }}, {{ item.adresse.administrative_area }},
                        {{ item.adresse.country_code }}
                    </div>

                    <a v-if="item.mail" class="place__address-info-talk" :href="`mailto:${item.mail}`">
                        {{ $t('place.talkWithUs') }}
                    </a>
                </div>
                <interactive-map id="carte" :uri="mapUrl" :height="'450px'" />
            </Container>

            <!-- EVENTS -->
            <Container v-if="futureEvent.length > 0" max-width="1074px" class="place__future-events">
                <ListCards
                    type="event"
                    :custom-gutter="54"
                    :items="futureEvent"
                    :title="`${$t('events.futureEvents')}`"
                    :more-link="{
                        txt: $t('events.seeAllEvents'),
                        url: $t('events.url'),
                    }"
                    trait
                />
            </Container>
        </template>
    </content-details>
</template>

<script>
import { cloneDeep } from 'lodash';
import qs from 'qs';

import ScrollMixin from '~/mixins/scroll-mixin.js';

import Container from '~/components/Container';
import ContentDetails from '~/components/layout/ContentDetails';
import InteractiveMap from '~/components/InteractiveMap';
import ListCards from '~/components/ListCards';
import MediaImage from '~/components/MediaImage';
import Paragraphs from '~/components/Paragraphs';
import Row from '~/components/Row';
import Wysiwyg from '~/components/Wysiwyg';

export default {
    head() {
        return this.$metaTags.head(this.metaTags);
    },

    components: {
        Row,
        Container,
        ContentDetails,
        InteractiveMap,
        ListCards,
        MediaImage,
        Paragraphs,
        Wysiwyg,
    },

    mixins: [ScrollMixin],

    props: {
        metaTags: {
            default: null,
            type: Array,
        },
        initData: {
            default: null,
            type: Object,
        },
    },

    data() {
        const initData = cloneDeep(this.initData);
        return {
            item: initData.item,
            futureEvent: initData.futureEvent,
        };
    },

    computed: {
        mapUrl() {
            let url = null;

            if (this.item && this.item.geolicalisation) {
                const lon = this.item.geolicalisation.lon;
                const lat = this.item.geolicalisation.lat;
                const title = this.item.title;
                const params = qs.stringify({
                    marker: `${lon};${lat};;texte dans la carte;;${title}`,
                    level: 12,
                });
                url = `https://geomatique.longueuil.quebec/carteweb/?${params}`;
            }
            return url;
        },
    },

    async asyncData({ app, query, store, params, error, env }, item) {
        const today = app
            .$moment()
            .startOf('day')
            .format('YYYY-MM-DD');
        const filters = {
            byRecurringDates: {
                condition: {
                    path: 'recurring_dates.value',
                    operator: '>=',
                    value: app
                        .$moment()
                        .tz('America/Toronto')
                        .format(),
                },
            },
        };
        const events = await app.$client.execute('events', 'getCollection', {
            filters,
            limit: 3,
            include: ['place'],
            sort: 'recurring_dates.value',
        });
        const futureEvent = app.$drupalComponentsAdapter.normalizeItems(events.data);

        return { item, futureEvent, today };
    },
};
</script>

<style lang="scss">
div.place {
    &__future-events,
    &__news {
        @include larger-content();
        @include media(lg) {
            max-width: 1134px !important;
        }
    }
}
</style>

<style lang="scss">
.place {
    margin-bottom: 20px;

    .el-col-offset-4 {
        @include media('sm-and-down') {
            margin-left: 0;
        }
    }

    @include media(sm) {
        margin-bottom: 100px;
    }

    &__map {
        @include larger-content();
    }

    &__precision {
        font-weight: rem(18px);
        font-weight: 400;
        line-height: 1.55em;
    }

    &__custom_content {
        margin-bottom: 100px;

        @include media(sm) {
            margin-bottom: 42px;
        }
    }

    &__wysiwyg {
        margin-bottom: 34px;
    }

    .place__image {
        display: flex;

        &-wrapper {
            margin-bottom: 26px;
            overflow: hidden;
            width: calc(100% + 40px);
            transform: translateX(-20px);

            @include media(sm) {
                margin-bottom: 40px;
                width: 100%;
                transform: none;
            }
        }
    }

    &__address {
        margin-bottom: 17px;
        margin-left: 0 !important;
        margin-right: 0 !important;
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
        max-width: 700px;

        &-container {
            display: flex;
            flex-flow: column;

            > div {
                width: 100%;
            }

            @include media(sm) {
                flex-flow: row;
            }
        }

        &-map {
            // background-color: $--color-gray;
            background-image: url('~assets/img/map_longueuil.png');
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            text-align: center;
            align-items: center;
            height: 218px;

            @include media(md) {
                height: auto;
            }

            &-container {
                display: flex;
                flex-flow: column;
                margin: auto;
                text-align: center;
            }
            &-icon {
                margin-bottom: 7px;
            }
            &-link {
                font-size: rem(13);
                font-weight: bold;
                line-height: 22px;
                text-decoration: none;
                color: $--color-primary-light-1;
            }
        }

        &-info {
            display: flex;
            align-items: center;
            padding-top: 24px;
            padding-bottom: 25px;

            @include media(sm) {
                padding-top: 33px;
                padding-bottom: 40px;
            }

            &-info-container {
                padding-left: 25px;
            }

            &-map-title {
                //color: $--color-dark-gray;
                max-width: 230px;
                margin-top: 1px;
                text-align: left;
                font-size: rem(18px);
                font-weight: 500;
                margin-bottom: 14px;

                @include media(sm) {
                    margin-bottom: 30px;
                }
            }

            &-title {
                margin-top: 0px;
                margin-bottom: 0px;
                font-size: rem(14px);
                font-weight: 500;
                line-height: 1.5em;

                @include media(sm) {
                    font-size: rem(18px);
                    font-weight: 700;
                    margin-bottom: 4px;
                }
            }

            &-position {
                font-size: rem(15px);
                font-weight: 400;
                line-height: 1.4em;
            }

            &-phones {
                font-size: rem(15px);
                font-weight: 300;
                line-height: 22px;
                & > span {
                    display: block;
                }
            }

            &-talk {
                display: block;
                font-size: rem(15px);
                font-weight: 500;
                color: $--color-info;
                text-decoration: underline;
                margin-top: 20px;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &__slider {
        position: relative;
        width: 100%;

        &-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            transition: 0.2s all;
            transition-timing-function: ease;

            &--left {
                left: -40px;

                &:hover {
                    left: -45px;
                }
            }

            &--right {
                right: -40px;

                &:hover {
                    right: -45px;
                }

                img {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__hours {
        margin-top: 28px;
        margin-bottom: 38px;

        @include media(sm) {
            margin-bottom: 42px;
        }

        &-title {
            margin-bottom: 1px;
            font-size: rem(18);
            font-weight: bold;

            @include media(sm) {
                margin-bottom: 4px;
            }
        }

        &-period {
            font-size: rem(14);
            font-weight: 400;
            line-height: 1.45em;
            margin-bottom: 0;

            @include media(sm) {
                margin-bottom: 2px;
                font-size: rem(18);
                font-weight: 400;
                line-height: 1.5em;
            }
        }
    }

    &__related-cards-item {
        margin-bottom: 25px;
    }

    &__map {
        margin-bottom: 16px;
        width: calc(100% + 40px);
        transform: translateX(-20px);

        > div {
            @include media(sm) {
                margin-left: 60px;
            }
        }

        @include media(sm) {
            width: 100%;
            transform: none;
            margin-bottom: 40px;
            max-height: auto;
        }

        .map__frame {
            margin-top: 96px;
            min-height: 325px;
            height: 325px;
            max-width: 1073px;

            @include media(sm) {
                min-height: 450px;
                margin-top: 0px;
            }
        }
    }

    &__address-info-container.map {
        position: absolute;
        top: 17.5%;
        left: 5%;
        transform: translateY(-50%);
        width: 90%;
        padding: 37px 39px 38px;
        box-sizing: border-box;
        background-color: white;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);

        @include media(sm) {
            top: 50%;
            left: -60px;
            width: 320px;
            padding: 37px 30px 55px;
        }

        .place__address-info-title {
            color: $--color-black;
        }
    }

    .items-list {
        margin-top: 0px;

        @include media(sm) {
            margin-top: 65px;
        }
    }

    // .title__htag--h1 {
    //     margin-top: 30px;
    //     margin-bottom: 4px;
    //     font-size: rem(25px);
    //     font-weight: 700;

    //     @include media(sm) {
    //         font-size: rem(42px);
    //         margin-bottom: 0px;
    //     }
    // }

    .parag__reusable {
        margin-bottom: 20px;
    }

    .accordion-list > li {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    .container {
        .title {
            padding-top: 0px;
            margin-top: 60px;

            @include media(sm) {
                padding-top: 60px;
                margin-top: 0px;
            }
        }
    }
}
</style>
